<template>
    <div>
        <v-alert
            border="right"
            colored-border
            color="secondary"
            type="info"
            elevation="1"
        >
        {{ title }}
        <div style="min-height:10px;"></div>
        <ul>
            <li>{{ text1 }}</li>
            <li>{{ text2 }}</li>
        </ul>
        </v-alert>
    </div>
</template>

<script>

export default {
    name: 'info-user-permit-holder',
    data: () => ({
        title: 'Uporabnik dovolilnice',
        text1: "Če oddajate vlogo za isto fizično ali pravno osebo izberite “Izberi obstoječega uporabnika”.",
        text2: "Če oddajate vlogo za drugo fizično ali pravno osebo izberite “Dodaj novega uporabnika”."
    })
}

</script>